import { CSFooter } from '@cms-components/footer/footer.interface';
import { graphql, useStaticQuery } from 'gatsby';

export const fetchFooterData = (): CSFooter => {
  const data = useStaticQuery(graphql`
    {
      contentstackFooter {
        uid
        footer_links {
          link_list {
            link_url
            link_text
            external_link
          }
          menu_section
        }
        utility_links {
          link_url
          link_text
          external_link
        }
        form {
          base_url
          form_id
          form_title
          success_message
          tracking_code
        }
        demo_button {
          link_url
          link_text
          external_link
        }
        email
        phone
        copyright
      }
    }
  `);

  return data?.contentstackFooter;
};
