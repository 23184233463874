// Styles
import React, { useState, useEffect, SyntheticEvent  } from 'react';
import { AccordionItem, AccordionProps } from '../../accordion.interface';
import {
  MuiTabsStyled,
  MuiTabStyled,
  MuiTabsContainerStyled,
  MuiTabsActiveIndicatorStyled,
  MuiTabsContentPanelStyled,
  MuiTabsContentStyled,
  MuiTabsHeadlineStyled,
  MuiTabsImageContentStyled,
  MuiTabsImageStyled,
  MuiTabsCopyStyled,
  CTAContainer
} from './horizontal-accordion.styles';
import { RichText } from '@src/shared/components';
import {
  AccordionItemsStyled
} from '../../accordion.styles';
import { PrimaryButton } from '@shared/components';
import useIsMobile from '../../accordion-utils';
import { default_breakpoints } from '@src/styles/breakpoints';

export const HorizontalAccordion = ({
    itemList,
    template
}: AccordionProps) =>{

    const DURATION = 6000;
    const [ autoSlide, setAutoSlide ] = useState<boolean | false>(true);
    const [ currentSlideProgress, setCurrentSlideProgress ] = useState<number | 0>(0);
    const [ active, setActive ] = useState<number | 0>(0);
    const { width, height } = useWindowDimensions();
    const isMobile = useIsMobile();

    const handleChange = (event: SyntheticEvent, newActive: number) => {
      if(active !== newActive) {
        setActive(newActive);
        setCurrentSlideProgress(0);
      }
    };

    const handleMouseOver = (index?: number) => (event: SyntheticEvent) => {
      if(index === active){
        setAutoSlide(false);
      }
    };

    const handleMouseLeave = (index?: number) => (event: SyntheticEvent) => {
      if(index === active){
        setAutoSlide(true);
      }
    };

    useEffect(()=>{
      const slidingInterval = setInterval(function () {
        if(!autoSlide || isMobile) {
          return;
        }
        if(currentSlideProgress >= 100) {
          setCurrentSlideProgress(0);
          setActive((active + 1 >= itemList.length) ? 0 : (active + 1));
        } else {
          setCurrentSlideProgress(currentSlideProgress + (100 * 200 / DURATION));
        }
      }, 200);

      return () => clearInterval(slidingInterval);
    }, [ itemList.length, autoSlide, active, currentSlideProgress, isMobile ]);

    return (
        <AccordionItemsStyled data-testid="accordion__items">
          <MuiTabsContainerStyled sx={{ maxWidth: width ? width : 1304 }}>
            <MuiTabsStyled
              value={active}
              onChange={handleChange}
              variant="scrollable"
              aria-label="Scrollable Auto-slide Tabs"
              scrollButtons={false}
              TabIndicatorProps={{ style: { display: 'none'} }}
              >
              {itemList.map((item: AccordionItem, index: number) =>
                <MuiTabStyled
                  icon={ active === index ? <MuiTabsActiveIndicatorStyled width={isMobile ? 100 : currentSlideProgress}/> : <></>}
                  key={index}
                  label={item.summary}
                  id={`simple-tab-${index}`}
                  aria-controls={`simple-tabpanel-${index}`}
                  onMouseOver={handleMouseOver(index)}
                  onMouseLeave={handleMouseLeave(index)}
                />
              )}
            </MuiTabsStyled>
          </MuiTabsContainerStyled>
          {itemList.map((item: AccordionItem, index: number) => (
            <MuiTabsContentPanelStyled
              template={template?.toLocaleLowerCase()}
              key={index}
              role="tabpanel"
              hidden={active !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
              onMouseOver={handleMouseOver(index)}
              onMouseLeave={handleMouseLeave(index)}
            >
              <MuiTabsContentStyled template={template?.toLocaleLowerCase()}>
                <MuiTabsHeadlineStyled template={template?.toLocaleLowerCase()}>{item.summary}</MuiTabsHeadlineStyled>
                <MuiTabsCopyStyled template={template?.toLocaleLowerCase()}>
                  <RichText content={item.details} />
                </MuiTabsCopyStyled>
                {(item.link.href && item.link.title) && (
                  <CTAContainer template={template?.toLocaleLowerCase()}>
                    <PrimaryButton
                        ariaLabel="see more"
                        path={item.link.href}
                        label={`${item.link.title}`}
                        size={'large'}
                        variant={'contained'}
                        icon={'text'}
                      />
                    </CTAContainer>
                  )}
              </MuiTabsContentStyled>
              {item.mobile_feature_image?.image?.url && (
                <MuiTabsImageContentStyled>
                  <picture>
                    {item.feature_image?.image?.url && <source media={`(min-width:${default_breakpoints.lg}px)`} srcSet={item.feature_image.image.url} />}
                    {item.tablet_feature_image?.image?.url && <source media={`(min-width:${default_breakpoints.md}px)`}  srcSet={item.tablet_feature_image.image.url} />}

                    <MuiTabsImageStyled
                    src={item.mobile_feature_image.image.url}
                    alt={item.feature_image.image_alt_text}
                  />
                  </picture>
                </MuiTabsImageContentStyled>
                )}
            </MuiTabsContentPanelStyled>
            ))}
      </AccordionItemsStyled>
    )
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({width: 767, height: 500});

  useEffect(() => {
    setWindowDimensions({width: window.outerWidth, height: window.outerHeight});
    function handleResize() {
      setWindowDimensions({width: window.outerWidth, height: window.outerHeight});
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    }
  }, []);

  return windowDimensions;
}
