import { CSFooter, FooterProps } from '../footer.interface';

export function footerMapper(query: CSFooter): FooterProps {
  const {
    copyright,
    demo_button,
    email,
    footer_links,
    phone,
    utility_links,
    logo,
    form
  } = query || {};

  return {
    demoButton: {
      isExternal: !!demo_button.external_link,
      text: demo_button.link_text,
      url: demo_button.link_url,
    },
    copyright: copyright,
    email: email,
    phone,
    form,
    utilityLinks: utility_links?.map((ulink) => ({
      isExternal: !!ulink.external_link,
      text: ulink.link_text,
      url: ulink.link_url,
    })),
    footerLinks: footer_links?.map((footer_link) => ({
      menuSection: footer_link.menu_section,
      linkList: footer_link.link_list.map((item) => ({
        isExternal: !!item.external_link,
        text: item.link_text,
        url: item.link_url,
      })),
    })),
  };
}
