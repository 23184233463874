import { Logo, MailIcon, PhoneIcon, PrimaryButton } from '@shared/components';
import BasicLink from '@shared/components/basic-link';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import { FooterLinks } from './components/';
import { fetchFooterData } from './footer.facade';
import { FooterProps } from './footer.interface';
import * as Styled from './footer.styles';
import { footerMapper } from './mappers/footer.mapper';
import { useState } from 'react';
import { useLocation } from '@reach/router';

import { ContactForm } from '@shared/components/contact-form';

export const Footer: React.FC<FooterProps> = ({
  copyright,
  demoButton,
  email,
  footerLinks,
  phone,
  utilityLinks,
  form
}) => {

    const isValidFormConfig: boolean =
    !!form?.base_url && !!form.form_id && !!form.tracking_code;

    const [addDemoForm, setAddDemoForm] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {

      // Reset each page visit
      setAddDemoForm(false);

      const interval = setInterval(() => {
        // Prevent re-rendering the same form
        if (!document.querySelector(`#mktoForm_${form?.form_id}`)) {
          setAddDemoForm(true);
          clearInterval(interval);
        } else {
          setAddDemoForm(false);
        }
      }, 100);

      return () => clearInterval(interval);
    }, [form?.form_id, location.pathname]);


  return (
    <div data-testid="footer__container">
      <CMSComponentWrapper maxWidth={1240} margin={[50, 0]}>
        <Styled.FooterContainer>
          <Styled.FooterLogo>
            <Link to={'/'} aria-label="Home Page">
              <Logo width={'148px'} />
            </Link>
          </Styled.FooterLogo>
          <Styled.FooterContainerLinkSection>
            {!!footerLinks &&
              footerLinks.map((footerLinkItem, index: number) => (
                <FooterLinks
                  key={`footer-links-${index}`}
                  linkList={footerLinkItem.linkList}
                  menuSection={footerLinkItem.menuSection}
                />
              ))}
          </Styled.FooterContainerLinkSection>
          <Styled.FooterContactInfoSection>
            <div>
              <Styled.DemoButtonContainer>
                <PrimaryButton
                  ariaLabel="get a demo"
                  path={demoButton?.url}
                  label={demoButton?.text}
                  size={'large'}
                  variant={'contained'}
                  icon={'text'}
                  fullwidth={true}
                />
              </Styled.DemoButtonContainer>
            </div>
            <Styled.FooterContactInfo>
              <Styled.FooterContactLink href={`tel:${phone}`}>
                <PhoneIcon sx={{ fontSize: 20 }} />
                <span>{phone}</span>
              </Styled.FooterContactLink>
              <Styled.FooterContactLink href={`mailto:${email}`}>
                <MailIcon sx={{ fontSize: 20 }} />
                <span>{email}</span>
              </Styled.FooterContactLink>
            </Styled.FooterContactInfo>
          </Styled.FooterContactInfoSection>
        </Styled.FooterContainer>
      </CMSComponentWrapper>
      <CMSComponentWrapper maxWidth={1240} border={'Top'} margin={[30, 20]}>
        <Styled.FooterUtility>
          <div>
            <Styled.FooterUtilityLinks>
              {!!utilityLinks &&
                utilityLinks.map(({ url, text, isExternal }, index) => (
                  <li key={`footer-utility-link-${index}`}>
                    <BasicLink
                      underline={true}
                      url={url}
                      text={text}
                      isExternal={isExternal}
                    />
                  </li>
                ))}
            </Styled.FooterUtilityLinks>
          </div>
          <div>
            <Styled.FooterUtilityCopyright>
              &#169;{copyright}
            </Styled.FooterUtilityCopyright>
          </div>
        </Styled.FooterUtility>
      </CMSComponentWrapper>
       {addDemoForm ? (
          <Styled.FormPanelForm>
          {isValidFormConfig ? (
            <ContactForm
              base_url={form?.base_url}
              title={form?.form_title}
              success_message={form?.success_message}
              tracking_code={form?.tracking_code}
              form_id={form?.form_id}
            />
          ) : (
            <span>Please provide a form ID, base URL and tracking code</span>
          )}
        </Styled.FormPanelForm>
        ) : null}
    </div>
  );
};

export const CMSFooter = () => {
  // GRAPHQL data
  const data = fetchFooterData();

  const mapper = footerMapper({ ...data });

  return <Footer {...mapper} />;
};

export default Footer;
